import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLinks from "../components/social_links"

const GlazenPage = () => (
  <Layout>
    <Seo title="Glazen" />
    <section className="page-header">
      <h1>Glazen</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content">
        <p>Je volgende bril is zoveel meer dan een mooi montuur. Bij Optiek De Leersnijder zoeken we samen een bril die perfect bij je ogen en je levensstijl past.</p>
        <p>Alles begint met een professionele oogmeting. Wij helpen je graag verder met deskundig advies over de beste brillenglazen voor jouw ogen. Spring dus zeker eens binnen!</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
        <StaticImage
            src="../images/glazen.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Glazen"
            placeholder="blurred"
            className="inline-block mx-2 w-12/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>

    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8">Soorten glazen</h2>
        <h4>Enkelvoudige glazen voor veraf of dichtbij</h4>
        <p>Heb je moeite om objecten in de verte scherp te zien, of om je favoriete boek te lezen? Dan is een bril met enkelvoudige glazen de oplossing. Een enkelvoudige bril corrigeert één afstand: dichtbij of veraf. Of je nu verziend of bijziend bent, onze enkelvoudige glazen passen bij elk montuur. Goed voor je ogen, perfect voor je stijl.</p>
        <h4 className="pt-8">Multifocale brillenglazen, scherpte op elke afstand</h4>
        <p>Wie moeite heeft om zowel veraf als dichtbij scherp te zien, kan geholpen worden met multifocale brillenglazen. In deze speciale glazen worden meerdere sterktes geslepen. Zo heb je maar één bril nodig om elke afstand goed te zien.</p>
        <h4 className="pt-8">Een computerbril voor achter het scherm</h4>
        <p>Breng je veel tijd door achter een computer en buig je tijdens het werken naar voor om je scherm beter te kunnen zien? Dan is een computerbril een goed idee. Met een speciale coating op de glazen kan je werken op een aangename, rustige manier zonder klachten.</p>
        <h4 className="pt-8">Gepolariseerde glazen tegen de zon</h4>
        <p>Reflecterend zonlicht op het water of in de auto kan vervelend zijn voor gevoelige ogen. Daarom bieden we bij Optiek De Leersnijder ook brillen aan met gepolariseerde glazen. Zij nemen de schitteringen van het licht weg en geven rust aan je ogen.</p>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-center">
        <StaticImage
            src="../images/glazen_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Glazen"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-4/6 h-1/2"
        />
        {/* <StaticImage
            src="../images/glazen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/4"
        /> */}
      </div>
      <div className="section-bg-left"></div>
    </section>

    <section className="section">
      <div className="section-content-left lg:col-end-7">
        <h2 className="pb-8">Meer dan een optiekzaak</h2>
        <p>Jouw ogen verdienen de beste zorg en jij verdient de beste service. Daarom bieden we bij aankoop van glazen de volgende diensten en garanties:</p>
        <ul className="list-disc list-outside ml-4">
          <li className="my-3"><strong className="text-dl-orange">6 maanden gewenningsgarantie</strong> voor multifocale glazen. Zo krijgen je ogen de tijd om te wennen aan je nieuwe bril.</li>
          <li className="my-3"><strong className="text-dl-orange">24 tot 36 maanden kwaliteitsgarantie.</strong> Vertonen je nieuwe glazen een kwaliteitsfout? Dan ontvang je vanzelfsprekend van ons onmiddellijk nieuwe brillenglazen.</li>
          <li className="my-3"><strong className="text-dl-orange">De PlusCard.</strong> Een gemerkt certificaat dat garant staat voor een kwaliteitsproduct.</li>
          <li className="my-3"><strong className="text-dl-orange">Gratis Traveller Service</strong> bij aankoop van multifocale glazen. Bij verlies of beschadiging van je bril op reis in Europa leveren we binnen de 48u een set noodbrillen op jouw vakantieadres.</li>
        </ul>
        <h4 className="pt-8">Een tweede paar glazen aan -50%</h4>
        <p>Een tweede bril is geen overbodige luxe. Of je nu een andere stijl zoekt of een multifocale bril wil combineren met een sportbril op sterkte, bij ons ontvang je bij elke nieuwe aankoop <strong>je tweede paar glazen aan de helft van de prijs</strong>. Twijfel je nog? Je kan tot 2 maanden na aankoop van je eerst bril genieten van dit aanbod. Kom gerust langs voor meer informatie!</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-end">
        <StaticImage
            src="../images/glazen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Glazen"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-2/3"
        />
      </div>
      {/* @apply relative bg-tdb-light bg-opacity-20 col-start-7 col-end-11 row-start-2 row-end-2 block w-full h-full top-10 z-0; */}
      <div className="section-bg-right col-start-8"></div>
    </section>
</Layout>
)

export default GlazenPage
